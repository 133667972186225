<script setup>
import {Modal as BaseModal} from 'bootstrap'
import {defineProps, defineExpose, onMounted, ref, defineEmits} from 'vue'
import {hideElementIfExist, showElementIfExist} from '@/plugins/helpers'

// Props & emits
let emit = defineEmits(['hidden'])

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  parentModalId: {
    type: String,
    default: '',
  },
  cantClose: {
    type: Boolean,
    default: false,
  },
  noHeader: {
    type: Boolean,
    default: false,
  },
  textAfterTitle: {
    type: String,
    default: '',
  },
})

// Refs
const modalId = ref(props.id ?? 'the-modal')
const modal = ref()

// Methods
onMounted(() => {
  let modalElement = document.getElementById(modalId.value)

  let modalBackdrop = props.parentModalId || props.cantClose ? 'static' : true
  modal.value = new BaseModal(modalElement, {backdrop: modalBackdrop})
  modalElement.addEventListener('hidden.bs.modal', () => {
    emit('hidden')
  })
})

const openModal = () => {
  modal.value.show()
}

const closeModal = () => {
  modal.value.hide()
}

const hideParentModal = () => {
  hideElementIfExist(props.parentModalId)
}
const showParentModal = () => {
  showElementIfExist(props.parentModalId)
}

const handleOpenModal = () => {
  props.parentModalId ? hideParentModal() : ''

  openModal()
}

const handleCloseModal = () => {
  props.parentModalId ? showParentModal() : ''

  closeModal()
}

defineExpose({handleOpenModal, handleCloseModal})
</script>

<template>
  <div
    class="modal fade"
    :id="modalId"
    v-bind="$attrs"
    tabindex="-1"
    :aria-labelledby="modalId + 'Label'"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="!noHeader" class="modal-header">
          <h5 class="modal-title" :id="modalId + 'Label'">
            {{ title }}

            <span v-if="textAfterTitle" class="fw-bold"> {{ textAfterTitle }}</span>
          </h5>
          <button v-if="!cantClose" type="button" class="btn-close" aria-label="Close" @click="handleCloseModal" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<style>
.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
